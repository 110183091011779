.report-admin {
  .ant-form-inline {
    .ant-form-item {
      &.button-search-company {
        margin-right: 0;
      }
      &.input-company {
        margin-right: 4px;
        width: 216px;
      }
    }
  }

  .ant-input-group-addon {
    padding: 0;
  }

  .customer-report-summary-wrapper .ant-card-body {
    padding: 16px 24px;
  }

  p.chart-legend {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &::before {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 10px;
    }
    &:nth-of-type(1)::before {
      background-color: #e8684a;
    }
    &:nth-of-type(2)::before {
      background-color: #5b8ff9;
    }
    &:nth-of-type(3)::before {
      background-color: #5ad8a6;
    }
    &:nth-of-type(4)::before {
      background-color: #5d7092;
    }
    &:nth-of-type(5)::before {
      background-color: #f6bd16;
    }
  }
  .btn-group-log {
    .pageLimit__selector {
      min-width: 124px;
      width: 100%;
    }
  }
  .chart-text-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    padding-top: 13px;
    padding-bottom: 11px;
    & > span.anticon.text-secondary {
      font-size: 32px;
      margin-bottom: 4px;
    }
  }
  .empty-wrapper {
    width: min(100%, 400px);
    margin-inline: auto;
    img {
      max-width: 309.68px;
      width: 100%;
      height: auto;
    }
  }
  @media screen and (max-width: 767px) {
    .tableTitle__wrapper > div {
      gap: 8px;
    }
    .btn-group-log > div {
      flex-direction: column;
      row-gap: 8px;
    }
  }
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;