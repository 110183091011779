.menu-sidebar.ant-menu {
  margin-top: 64px;
  &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item.ant-menu-item-selected,
  .ant-menu-item:active {
    background: @green-1;
  }
  &.ant-menu-inline,
  &.ant-menu-vertical {
    border-right: none;
  }
  .tagSoon {
    margin-left: 8px;
    border-radius: 10px;
    background-color: #ff4d4f;
    color: #fff;
    border: none;
  }
}
.btn-logout-wrapper.ant-menu-light {
  position: relative;
  &:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #e5faec;
  }
  &.ant-menu-inline,
  &.ant-menu-vertical {
    border-right: none;
  }
  .ant-menu-item {
    color: #ff4d4f;
    &:hover {
      color: #ff4d4f;
    }
  }
  .btn-logout {
    text-align: left;
    border-color: transparent;
    background: transparent;
    color: #ff4d4f;
    cursor: pointer;
  }
}
.ant-layout-sider {
  background: #fff;
}
.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;