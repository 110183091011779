.billing-user {
  .next-bill img {
    max-width: 320px;
  }
  .invoice-wrapper h5.ant-typography .faktur-wrapper h5.ant-typography,
  .npwp-img h5.ant-typography {
    font-weight: 500;
    margin-bottom: 4px;
  }
  .tagihan-img,
  .faktur-img,
  .error-wrapper {
    width: min(100%, 400px);
    margin-inline: auto;
  }
  .card-next-billing .ant-card-head {
    background: #0d9f3f;
    color: #fff;
  }
}

.ant-tabs-top.billing-tab > .ant-tabs-nav {
  margin-bottom: 8px;
}

.ant-col .card-detail {
  border: 1px solid var(--polar-green-7, #0d9f3f);
  background: var(--neutral-1, #fff);
  padding: 12px 24px;
}

.card-detail .ant-card-head {
  border: 1px solid var(--polar-green-7, #0d9f3f);
  background: var(--neutral-1, #fff);
}

.detail-wrapper {
  background: var(--neutral-1, #fff);
  padding: 12px 16px;
}

.text-status {
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

@media (max-width: 768px) {
  .ant-col .card-detail {
    border: unset;
    padding: 0;
  }
}

.card-payment .ant-card-body {
  padding: 16px 12px 16px 12px;
}

.ant-card-bordered {
  border-radius: 4px !important;
}

.card-payment:hover {
  outline: 4px solid #18c7531a;
  border-radius: 4px !important;
}

.font-alert-warning {
  color: #fa8c16;
}

.alert-waiting {
  padding: 1px 8px 1px 8px !important;
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;