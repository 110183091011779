.billing-admin {
  .invoice-summary-wrapper .ant-card-body {
    padding: 16px 24px;
  }
  .card-display-invoice .ant-card-body {
    padding: 16px 24px;
  }
  .invoice-admin-table .ant-table-title,
  .faktur-admin-table .ant-table-title {
    padding-inline: 8px;
  }
  .invoice-table-tag.paid-tag {
    color: #18c753;
  }
  .ant-tabs-top.billing-tab > .ant-tabs-nav {
    margin: 0;
  }
  .ant-form#f-search-company {
    :nth-child(1),
    :nth-child(2) {
      margin-right: 0;
    }
    :nth-child(1).ant-form-item {
      margin-right: 4px;
    }
  }
  .btn-group-log {
    .invoiceStatus__selector {
      max-width: 142px;
      width: 100%;
    }
    .pageLimit__selector {
      min-width: 124px;
      width: 100%;
    }
  }
  .empty-wrapper {
    width: min(100%, 400px);
    margin-inline: auto;
    img {
      width: 300px;
      height: 240px;
    }
  }
  .btn-group-log {
    .input-company {
      min-width: 216px;
      width: 100%;
    }
    .invoiceStatus__selector {
      min-width: 142px;
      width: 100%;
    }
  }
  .ant-input-group-addon {
    padding: 0;
  }
  .formFilter {
    width: 100%;
    gap: 12px 32px;
    flex-flow: row nowrap;
    .ant-form-item {
      margin-right: 0;
      flex: 1;
    }
    .ant-row {
      max-height: 32px;
      flex-flow: row nowrap;
    }
    .ant-form-item-label > label::after {
      margin: 0 4px 0 0;
    }
    .button-advance-filter {
      margin-left: auto;
    }
  }
  @media screen and (max-width: 767px) {
    .tableTitle__wrapper > div {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 10px;
    }
    .btn-group-log {
      width: 100%;
    }
  }
  @media (max-width: 1199px) {
    .formFilter {
      gap: 12px 16px;
      flex-flow: row wrap;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .formFilter {
      .ant-form-item {
        flex: 1 1 calc(50% - 16px);
        max-width: 50%;
      }
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .tableTitleAccordion__wrapper > div {
      column-gap: 10px;
      .title-text {
        white-space: nowrap;
      }
      .filter-select {
        min-width: 93px;
      }
      .btn-group-log {
        flex-wrap: wrap;
        justify-content: flex-end;
      }
      .inputDropdown {
        flex: 1 1 auto;
      }
    }
    .tableTitle__wrapper > div {
      column-gap: 10px;
      .title-text {
        white-space: nowrap;
      }
    }
  }
  @media (min-width: 768px) and(max-width:991px) {
    .tableTitleAccordion__wrapper {
      .btn-group-log {
        flex-flow: row wrap;
        justify-content: end;
      }
    }
  }
  @media (min-width: 768px) and(max-width:1199px) {
    .tableTitle__wrapper {
      .btn-group-log > div {
        flex-flow: row wrap;
        row-gap: 8px;
        justify-content: flex-end;
      }
    }
  }
  @media (max-width: 767px) {
    .formFilter {
      .ant-form-item {
        flex: 1 1 auto;
      }
    }
  }
  @media (max-width: 767px) {
    .tableTitleAccordion__wrapper {
      & > div:nth-child(1) {
        gap: 10px;
      }
      .btn-group-log {
        flex-flow: row wrap;
        justify-content: flex-end;
        width: 100%;
        .inputDropdown {
          flex: 1 1 50%;
        }
      }
    }
    .tableTitle__wrapper {
      & > div {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 10px;
      }
      .btn-group-log {
        width: 100%;
      }
      .btn-group-log .inputDropdown {
        flex: 1 1 auto;
        max-width: none;
      }
      .btn-group-log > div {
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: 0.5rem;
      }
    }
  }
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;