.notification-page {
  .notification-list-card .ant-card-body {
    padding: 0;
  }
  .notification-list-card .ant-list-pagination {
    padding-right: 24px;
    padding-bottom: 16px;
  }
  .menu-notification-body {
    & .notif-body {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      white-space: initial;
      width: 100%;
    }
    & .date-notif {
      display: flex;
      margin-bottom: 4px;
      padding-block: 6px;
      align-items: center;
    }
  }
  .menu-notification-body:hover {
    background-color: #f5f5f5;
    & .notif-body {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      white-space: initial;
      width: 100%;
    }
    & .date-notif {
      display: flex;
      margin-bottom: 4px;
      padding-block: 6px;
      align-items: center;
    }
  }
}
.notification-unread {
  background: #e5faec;
}
.notification-page, .notification-detail {
  .notification-support-card {
    & > div {
      gap: 24px 48px;
    }
    background: #e6f7ff;
    padding: 24px;
    border: 1px solid #91d5ff;
  }
  @media only screen and (max-width: 1199px) {
    .notification-support-card {
      & > div {
        flex-direction: row;
        align-items: center;
      }
    }
    .notification-information-card {
      display: none;
    }
    .notification-cs-img {
      margin-top: unset;
    }
  }
  @media only screen and (max-width: 767px) {
    .notification-cs-img {
      display: none;
    }
  }
}
.notification-detail .categoryNotification {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;