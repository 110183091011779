.profile-admin {
  .btn-admin-profile {
    width: 100%;
  }
  .img-profile {
    border-radius: 50%;
    width: 180px;
    height: 180px;
    object-fit: cover;
    object-position: center center;
  }
  .upload-btn .ant-upload {
    display: none;
  }
  .image-profile-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover .upload-btn .ant-upload {
      display: inline-block;
    }
  }
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;