.kelola-paket {
  .package-summary-wrapper .ant-card-body {
    padding: 16px 24px;
  }
  .card-display-package .ant-card-body {
    padding: 16px 24px;
  }
  .paper-id-image-wrapper {
    float: left;
    & img {
      max-height: 40px;
    }
  }
  .form-package {
    & .ant-form-item-label {
      padding: 0 0 4px;
    }
    & .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
  .invoice-paperId {
    padding-inline: 0;
    border: none;
    height: auto;
    span.underline {
      margin-left: 4px;
    }
  }
  .ant-tabs-top.package-tab > .ant-tabs-nav {
    margin: 0;
  }
  .zoho-id .ant-form-item-label > label .ant-form-item-tooltip {
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    writing-mode: horizontal-tb;
    margin-inline-start: 4px;
  }
  .anticon.checkIcon {
    font-size: 20px;
  }
  .empty-wrapper {
    width: min(100%, 400px);
    margin-inline: auto;
    img {
      width: 300px;
      height: 240px;
    }
  }
}
.ant-tooltip.zohoLabel__tooltip {
  max-width: max-content;
  width: 100%;
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;