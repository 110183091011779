.pelanggan-admin {
  .customer-summary-wrapper {
    .ant-card-body {
      padding: 16px 24px;
    }
  }
  .img-profile {
    border-radius: 50%;
    width: 180px;
    height: 180px;
    object-fit: cover;
    object-position: center center;
  }
  .upload-btn .ant-upload {
    display: none;
  }
  .image-profile-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover .upload-btn .ant-upload {
      display: inline-block;
    }
  }
  .empty-wrapper {
    width: min(100%, 400px);
    margin-inline: auto;
    img {
      width: 300px;
      height: 240px;
    }
  }
  .ant-descriptions-bordered.drawer-desc-wrapper-clients {
    .ant-descriptions-view {
      border: none;
      .ant-descriptions-row {
        .ant-descriptions-item-label {
          background: none;
          padding: 16px;
          border-right: none;
          width: 150px;
          & span {
            font-weight: 500;
          }
        }
      }
      .ant-descriptions-item-content {
        padding: 0;
      }
    }
  }
  .list-domain-wrapper {
    box-shadow: inset 0px -1px 0 #f0f0f0;
    .left-domain,
    .right-domain {
      padding: 9px 16px;
    }
  }
  .contact-wrapper {
    padding: 8px;
    border-bottom: 1px solid #f0f0f0;
    .btn-profile {
      visibility: hidden;
    }
    &:hover .btn-profile {
      visibility: visible;
    }
  }
  .packageLine {
    column-gap: 24px;
    & > p {
      white-space: nowrap;
    }
    & > span.ant-tag {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .formFilter {
    width: 100%;
    gap: 12px 32px;
    flex-flow: row nowrap;
    .ant-form-item {
      margin-right: 0;
      flex: 1;
    }
    .ant-row {
      max-height: 32px;
      flex-flow: row nowrap;
    }
    .ant-form-item-label > label::after {
      margin: 0 4px 0 0;
    }
    .button-advance-filter {
      margin-left: auto;
    }
  }
  .ant-btn > span + .anticon {
    margin-left: 4px;
  }
  .title-text > p {
    white-space: break-spaces;
  }
  button.buttonNpwp {
    padding: 0;
    white-space: normal;
    text-align: left;
    height: auto;
  }
  @media (max-width: 1199px) {
    .formFilter {
      gap: 12px 16px;
      flex-flow: row wrap;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .formFilter {
      .ant-form-item {
        flex: 1 1 calc(50% - 16px);
        max-width: 50%;
      }
    }
  }
  @media (max-width: 767px) {
    .formFilter {
      .ant-form-item {
        flex: 1 1 calc(50% - 16px);
      }
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .tableTitleAccordion__wrapper > div {
      column-gap: 10px;
      .title-text {
        white-space: nowrap;
      }
      .filter-select {
        min-width: 93px;
      }
      .btn-group-log > div {
        row-gap: 8px;
      }
    }
    .tableTitle__wrapper > div {
      column-gap: 10px;
      .title-text {
        white-space: nowrap;
      }
    }
    .list-domain-wrapper {
      .left-domain {
        padding: 0;
      }
      .right-domain {
        padding: 9px 8px;
      }
    }
  }
  @media (min-width: 768px) and(max-width:991px) {
    .tableTitleAccordion__wrapper {
      .btn-group-log {
        flex-flow: row wrap-reverse;
        justify-content: end;
      }
      .input-company {
        flex: 1 1 auto;
        max-width: 216px;
      }
    }
    .tableTitle__wrapper {
      .btn-group-log > div {
        flex-flow: row wrap;
        row-gap: 8px;
        justify-content: flex-end;
      }
      .btn-group-log .input-company {
        flex: 1 1 auto;
        max-width: 216px;
      }
    }
  }
  @media (max-width: 647px) {
    .tableTitleAccordion__wrapper {
      .filter-text {
        margin-left: auto;
        order: 3;
      }
    }
  }
  @media (max-width: 767px) {
    .tableTitleAccordion__wrapper {
      & > div:nth-child(1) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 10px;
      }
      .btn-group-log {
        flex-flow: row wrap;
        width: 100%;
      }
      .input-company {
        flex: 1 1 50%;
      }
    }
    .tableTitle__wrapper {
      & > div {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 10px;
      }
      .btn-group-log {
        width: 100%;
      }
      .btn-group-log .input-company {
        flex: 1 1 auto;
      }
    }
  }
  @media (min-width: 1400px) {
    .profile-client__wrapper {
      .profileClient__left.ant-col-xl-8 {
        display: block;
        flex: 0 0 25%;
        max-width: 25%;
      }
      .profileClient__right.ant-col-xl-16 {
        display: block;
        flex: 0 0 75%;
        max-width: 75%;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    .profileClient__right .contactDomain__row .ant-col-xl-12 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;