.email-api-page {
    .ant-tag-rose {
        color: #f5222d;
    }

    .status-email-server-card {
        .ant-card-body {
            padding: 24px;

            .ant-collapse>.ant-collapse-item>.ant-collapse-header {
                padding-left: 0;
                padding: 20px 24px;
                align-items: center;
            }

            .ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
                padding-top: 0;
                padding-bottom: 8px;
            }
        }

        .text-secondary {
            margin: 0;
        }

        .status-email {
            &__table {
                & .status-email__nested .ant-table {
                    margin: -16px -16px;
                }
            }
        }

        .status-email-server-body-text {
            margin-bottom: 30px;
        }

        .setup-collapse .ant-collapse-item {
            border-bottom: 1px solid #d9d9d9;

            &.collapse-fail {
                animation: pulse 2s ease-out infinite;
                border-radius: 2px;
            }

            .ant-collapse-header {
                flex-direction: row-reverse;

                .ant-collapse-extra {
                    margin-left: 0;
                    margin-right: auto;
                }
            }

            .hidden-icon .ant-table-row-expand-icon-cell span {
                display: none;
            }

            .icon-danger .ant-table-row-expand-icon {
                border-color: currentColor;

                &.ant-table-row-expand-icon-expanded,
                &.ant-table-row-expand-icon-collapsed {
                    color: #ff4d4f;
                }
            }

            .icon-success .ant-table-row-expand-icon {
                border-color: currentColor;

                &.ant-table-row-expand-icon-collapsed,
                &.ant-table-row-expand-icon-expanded {
                    color: #0d9f3f;
                }
            }
        }
    }

        .ant-table-tbody .ant-table-row {
        .ant-table-cell {
                color: var(--character-title-85, rgba(0, 0, 0, 0.85));
        
                /* Body/medium */
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                /* 157.143% */
              
            }

            .ant-typography {
        color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
        
            /* Body/regular */
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
            }
    }

        .ant-typography-expand,
        .ant-typography-edit,
        .ant-typography-copy {
            margin-left: 0;
        }

    
    .empty-collapse {
        width: min(400px, 100%);
        height: auto;

        img {
            height: 240px;
        }
    }

    .ant-table-tbody>tr>td.params-value-col,
    .ant-table-tbody>tr>td.btn-table-col {
        padding: 9px 16px;
    }

    .params-value-col>.ant-typography {
        margin-bottom: 0;
    }

    .collapse-fail,
    .collapse-fail>.ant-collapse-content>.ant-collapse-content-box {
        background: #fff1f0;
    }

    .btn-table-col {
        text-align: right;
    }

    .dns-table>.ant-typography {
        margin-bottom: 0;
    }

    .params-value-col>.ant-typography>.ant-typography-copy,
    .dns-table>.ant-typography>.ant-typography-copy {
        color: #00000040;
    }

    .ant-btn-icon-only {
        &.act-pass {
            width: max-content;
            color: #00000040;
        }
    }

    .list-domain-wrapper {
        box-shadow: inset 0px -1px 0px #f0f0f0;

        .left-domain {
            padding: 9px 4px 9px 16px;
        }

        .right-domain {
            padding: 9px 16px 9px 4px;
        }
    }

    .domain-form-dashboard.blob,
    .sample__wrapper .blob,
    .list-domain-wrapper .right-domain .blob {
        box-shadow: 0 0 0 0 rgba(#fadb14, 1);
        border-radius: 2px;
        animation: pulse 2s ease-out infinite;

        &::before {
            display: block;
            opacity: 1;
            content: "";
            background-color: transparent;
            position: absolute;
            border-radius: 2px;
            outline: 1px solid #0d9f3f;
            box-shadow: 0 0 0 0 rgba(#fadb14, 1);
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            animation: pulseback 2s 1s ease-out infinite;
        }
    }

    .response {
        color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
            font-family: IBM Plex Mono;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
    }

    @keyframes pulse {
        0% {
            box-shadow: 0 0 0 0 rgba(#fadb14, 1);
        }

        70% {
            box-shadow: 0 0 0 12px rgba(#fadb14, 0);
        }

        100% {
            box-shadow: 0 0 0 0 rgba(#fadb14, 0);
        }
    }

    @keyframes pulseback {
        0% {
            box-shadow: 0 0 0 0 rgba(#fadb14, 1);
        }

        70% {
            box-shadow: 0 0 0 12px rgba(#fadb14, 0);
        }

        100% {
            box-shadow: 0 0 0 0 rgba(#fadb14, 0);
        }
    }
}

.process-domain__wrapper {
    max-width: 800px;
    width: 100%;

    & .btn-nav {
        max-width: 348px;
        width: 100%;
    }
}
@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;