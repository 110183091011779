.dashboard-header.ant-layout-header {
  background: #fff;
  padding: 0;
}

.dashboard-header {
  background: red;
}

.dashboard-header > h4 {
  padding: 20px 24px 24px;
  margin: 0;
  font-weight: 500;
  line-height: 28px;
}

.page-header {
  &.ant-page-header {
    padding: 12px 24px 16px 20px;
  }
  .ant-page-header-heading-title {
    font-weight: 500;
  }
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;