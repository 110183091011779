.manageNotification {
  .tableWrapper {
    padding: 8px 24px 24px;
    background-color: white;
    .ant-table-title {
      padding-bottom: 24px;
    }
  }
  .empty-wrapper {
    width: min(100%, 400px);
    margin-inline: auto;
    img {
      width: 300px;
      height: 240px;
    }
  }
}

.createNotification {
  &__left {
    max-width: 852px;
    width: 100%;
    padding: 16px 24px;
    background: white;
  }
  &__right {
    max-width: 348px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    .ant-select.category-list {
      width: 100%;
      max-width: 85px;
    }
  }
}

.viewNotification {
  &__content {
    padding: 24px;
    background-color: white;
    .categoryNotification {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
  }
}

.EditNotifAdmin {
  &__left {
    max-width: 852px;
    width: 100%;
    padding: 16px 24px;
    background: white;
  }

  &__right {
    max-width: 348px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    .ant-select.category-list {
      width: 100%;
      max-width: 85px;
    }
  }

    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
    }
  
    &__spinner {
      text-align: center;
    }
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;