.dashboard-wrapper {
  .header-dashboard {
    position: fixed;
    z-index: 1051;
    width: 100%;
    padding: 0 16px;
    background: #1f1f1f;
  }
  .badge-icon {
    font-size: 20px;
    color: #fff;
    & > .ant-badge-count {
      right: -10px;
      box-shadow: none;
    }
  }
  .user-button {
    transition: none;
  }
  .user-button,
  .user-button:hover,
  .user-button:focus {
    background: transparent;
    border: none;
    color: #fff;
  }
}
.info-circle-icon-notification {
  font-size: 16px;
  margin: 15px 8px 15px 12px;
}

.text-notification-header {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}

.ant-dropdown-menu-item.menu-item-notification {
  padding: 0;
}

.menu-notification-dropdown {
  & .notif-body {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: initial;
    width: 100%;
  }
  & .notif-category {
    align-items: center;
  }
}
.menu-notification-dropdown:hover {
  background-color: #f5f5f5;
  & .notif-body {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: initial;
    width: 100%;
  }
  & .notif-category {
    align-items: center;
  }
}

.notif-box-nabvar {
  background-color: #fff;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.notification-dropdown {
  & > :nth-child(1) {
    padding: 8px 60px;
  }
}
.img-notification {
  padding: 8px 60px;
}
.box-notification {
  height: 432px;
  width: 440px;
  overflow: auto;
}
.ant-btn.btn-act-notif {
  border-top: 0.25px solid #d9d9d9;
  border-right: 0.25px solid #d9d9d9;
  border-bottom: 0.25px solid #d9d9d9;
  height: auto;
}

.ant-btn.btn-act-notif:hover {
  border-top: 0.25px solid #0d9f3f;
  border-right: 0.25px solid #0d9f3f;
  border-bottom: 0.25px solid #0d9f3f;
  border-left: 0.25px solid #0d9f3f;
  height: auto;
  color: #0d9f3f;
}

.menu-item-notification {
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  &.h-auto {
    height: auto;
  }
}
.ant-dropdown-menu.dropdownMenu {
  box-shadow: none;
}
.navbarUser .btn-logout {
  padding: 4px 12px;
  text-align: left;
  width: 100%;
  &:hover {
    background-color: #ff7875;
    color: #fff;
  }
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;