.ant-drawer-body {
  padding: 24px 26.68px 0 30px !important;
}

.ant-descriptions-bordered.drawer-desc-wrapper
  .ant-descriptions-view
  .ant-descriptions-row
  .ant-descriptions-item-label {
  padding: 0px 12px;
  width: 98px;
}

.ant-descriptions-bordered.drawer-desc-wrapper
  .ant-descriptions-view
  .ant-descriptions-item-content {
  padding: 13px 12px;
}

.ant-table-tbody > tr > td {
  padding-top: 17px;
  padding-bottom: 17px;
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;