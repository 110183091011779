.kelola-admin {
  .tabs__wrapper {
    background-color: white;
    padding: 12px 24px 24px;
  }
  .activity-log-admin.ant-tabs-top > .ant-tabs-nav {
    margin: 0;
  }
  .ant-select-item-option.log-admin-selectall {
    border-bottom: 1px solid rgba(49, 49, 49, 0.03);
  }
  .pageLimit__selector {
    min-width: 124px;
    width: 100%;
  }
  .selectAdmin {
    width: 138px;
    .ant-select-selection-placeholder {
      color: #000000d9;
    }
  }
  .w-12.ant-tooltip-disabled-compatible-wrapper button {
    width: 48px;
  }
  @media screen and (max-width: 767px) {
    .btn-group-log > div {
      flex-flow: row wrap;
      gap: 8px;
      justify-content: end;
    }
  }
  .empty-wrapper {
    width: min(100%, 400px);
    margin-inline: auto;
    img {
      width: 300px;
      height: 240px;
    }
  }
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;