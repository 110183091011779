.main-dashboard-user {
  h2.ant-typography.header-2,
  .ant-typography.header-2 h2 {
    font-weight: 500;
    margin-bottom: 4px;
  }

  .ant-typography.ant-typography-primary {
    color: #1890ff;
  }

  .text-emaildashboard {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }

  .percentchart-dashboard {
    max-width: 376px;
    width: 100%;
  }

  .emailcard-dashboard,
  .domaincard-dashboard {
    position: relative;
  }

  .card-wrapper-chart .ant-card-body {
    padding: 16px 24px;
  }
  .column-chart-right {
    & .chart-right .ant-card-body {
      padding: 16px 24px;
    }
  }

  .line-chart-dashboard {
    width: 100%;
  }

  .empty-line-chart-text > h5 {
    font-weight: 500;
  }

  .chart-text-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 12px 0;
    & > span.anticon.text-secondary {
      font-size: 28px;
      padding: 4px;
      margin-bottom: 4px;
    }
  }

  p.chart-legend {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &::before {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 10px;
    }

    &:nth-of-type(1)::before {
      background-color: #e8684a;
    }

    &:nth-of-type(2)::before {
      background-color: #5b8ff9;
    }

    &:nth-of-type(3)::before {
      background-color: #5ad8a6;
    }

    &:nth-of-type(4)::before {
      background-color: #5d7092;
    }
  }
  .icon-left {
    font-size: 32px;

    &.done > svg {
      filter: brightness(0.5) sepia(1) hue-rotate(-70deg) saturate(5);
    }
  }
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;