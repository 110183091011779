.profile-user {
  button.buttonNpwp {
    padding: 0;
    white-space: normal;
    text-align: left;
    height: auto;
  }

  .ant-switch-checked {
    background-color: #1890ff;
  }
  .contact-wrapper {
    padding: 8px;
    border-bottom: 1px solid #f0f0f0;
    .btn-profile {
      visibility: hidden;
    }
    &:hover .btn-profile {
      visibility: visible;
    }
  }
  .ant-checkbox-wrapper.ml-0 {
    margin-left: 0;
  }
  .img-profile {
    border-radius: 50%;
    width: 180px;
    height: 180px;
    object-fit: cover;
    object-position: center center;
  }
  .upload-btn .ant-upload {
    display: none;
  }
  .image-profile-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover .upload-btn .ant-upload {
      display: inline-block;
    }
  }
  .description__wrapper {
    .ant-descriptions-item-content .ant-typography.mb-0 {
      margin-bottom: 0;
      .ant-typography-copy {
        color: #00000040;
      }
    }
    .rowNpwp .ant-descriptions-item-content {
      flex-direction: column;
    }
  }
  .ant-btn-icon-only.act-pass {
    width: max-content;
    color: #00000040;
  }
  .list-domain-wrapper {
    box-shadow: inset 0px -1px 0 #f0f0f0;
    .left-domain,
    .right-domain {
      padding: 9px 16px;
    }
  }
  .profile-user__left {
    max-width: 288px;
    width: 100%;
  }
  .profile-user__right {
    width: 100%;
    .right-res-profile {
      width: 50%;
    }
  }
  .w-12.ant-tooltip-disabled-compatible-wrapper button {
    width: 48px;
  }

  @media only screen and (max-width: 991px) {
    .profile-user__wrapper {
      flex-wrap: wrap;
    }
    .profile-user__left {
      max-width: unset;
      column-gap: 24px;
      width: 100%;
      flex-direction: row;
      .notification-settings {
        max-width: 616px;
        width: 100%;
      }
      .profile-picture {
        max-width: 616px;
        width: 100%;
        .profile-picture__card {
          // display: flex;
          column-gap: 16px;
          .profile-picture__wrapper {
            display: flex;
            flex-direction: column;
            .profile-picture__content {
              display: flex;
              flex-direction: column;
              width: 100%;
            }
          }
        }
      }
    }
    .profile-user__right {
      .profile-user__account,
      .profile-user__company {
        flex-wrap: wrap;
        .right-res-profile {
          width: 100%;
        }
      }
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1330px) {
    .profile-user__right {
      .profile-user__account,
      .profile-user__company {
        flex-wrap: wrap;
        .right-res-profile {
          width: 100%;
        }
      }
    }
  }
  .blob {
    animation: pulse 2s infinite;
    border-radius: 2px;
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgb(13 159 63 / 20%);
    }
    70% {
      box-shadow: 0 0 0 5px rgb(13 159 63 / 20%);
    }
    100% {
      box-shadow: 0 0 0 0 rgb(13 159 63 / 20%);
    }
  }
}
form#newContact .ant-checkbox-wrapper + .ant-checkbox-wrapper,
form#editContact .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;