.thumbnail-container-cta {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 20px 4px;
}

.silverplay-icon-cta {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
}

.silverplay-icon-cta svg {
  width: 36px;
  height: 36px;
}

.yellow-card {
  background: #fffbe6;
  border: 1px solid #ffe58f;
}

.blue-card {
  background: #e6f7ff;
  border: 1px solid #91d5ff;
}

.domain-cta__wrapper {
  display: flex;
  justify-items: center;
  background: #fffbe6;
  border: 1px solid #ffe58f;
  & .domain-cta__right {
    padding-inline: 30px;
  }
}

.configuration-guide-left {
  display: flex;
  flex-direction: column;
}

.cta-progress {
  width: 100%;
  .cta-progress__header {
    border-bottom: 1px solid #f0f0f0;
    background: #057b2d;
    .cta-progress__title {
      h3,
      h5 {
        color: #fff;
      }
    }
    .cta-progress__extra {
      & .ant-progress-line {
        display: flex;
        align-items: center;
        .ant-progress-outer {
          margin: 0;
          padding: 0;
        }
        .ant-progress-text {
          width: max-content;
          color: #8fedaf;
        }
      }
    }
  }
  .cta-progress__body {
    .ant-list-item {
      .extra {
        min-width: 99px;
        text-align: right;
      }
      & .done h4.ant-list-item-meta-title {
        text-decoration: line-through;
      }
    }
  }
}

.documentation-cta__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e6f7ff;
  border: 1px solid #91d5ff;
  & .documentation-cta__left {
    max-width: 932px;
    padding: 24px 0 24px 24px;
  }
  & .documentation-cta__right {
    padding-inline: 30px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .domain-cta__right {
    display: none;
  }
  .documentation-cta__right {
    display: none;
  }

  .guide {
    width: 170px;
    margin-bottom: 8px;
  }
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;