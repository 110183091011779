.title-modal {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));

  /* H5/medium */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

.paragraph-modal {
  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));

  /* Body/regular */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.ant-input-group-addon {
  padding: 0;
}

.logo-info {
  width: 70px;
  height: 50px;
}

.order-list {
  padding-inline-start: 16px;
  margin-bottom: 0;
  margin-top: 8px;
}

.selected-payment {
  border: 1px solid #0d9f3f !important;
  outline: 4px solid #18c7531a;
  border-radius: 4px !important;
}

.ant-card .ant-card-bordered {
  border-radius: 4px;
}

.change-pay span.ant-radio + * {
  padding-right: 0;
  padding-left: 12px;
  width: 100%;
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;