.report-email-user {
  .col-maildev {
    color: #0d9f3f;
  }

  .col-mailfail {
    color: #ff4d4f;
  }

  .col-detail-action {
    color: #0d9f3f;
    cursor: pointer;
  }

  .tol-report-detail {
    width: 280px;
  }

  .empty-wrapper {
    width: min(100%, 400px);
    margin-inline: auto;
    img {
      width: 300px;
      height: 240px;
    }
  }
}

.ant-alert-info .ant-alert-icon.report-email {
  color: #0f52b9;
  background-color: #e6f7ff;
}

.ant-alert-info.report-email {
  background-color: #e6f7ff;
  font-weight: 400;
  border: 1px solid #91d5ff;
}

.ant-alert.report-email {
  margin-bottom: 290px;
}

.title-report-email .ant-drawer-title {
  font-size: 20px;
  line-height: 28px;
}

.drawer-log-detail {
  margin-top: 64px;
  &.ant-drawer-open .ant-drawer-content-wrapper .ant-drawer-header {
    padding-top: 40px;
    padding-inline: 30px;
  }
}

.ant-drawer .ant-drawer-header {
  border-bottom: none;
}

.ant-descriptions-bordered.drawer-desc-wrapper {
  .ant-descriptions-view {
    border: none;
    .ant-descriptions-row {
      .ant-descriptions-item-label {
        background: none;
        padding: 16px;
        border-right: none;
        width: 120px;
        & span {
          font-weight: 500;
        }
      }
    }
    .ant-descriptions-item-content {
      padding: 16px;
    }
  }
}

.ant-tooltip.tool-full-report {
  max-width: 301px;
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;