.dokumentasi-user {
  ul.list-doc {
    list-style: circle;
    padding-inline-start: 20px;
    & li .ant-btn-link {
      padding: 0;
      color: #1890ff;
    }
  }
  .drawer-doc.ant-drawer-open .ant-drawer-content-wrapper {
    & .ant-drawer-body {
      padding: 0;
      padding-inline: 30px;
    }
    & .ant-drawer-header {
      padding-inline: 30px;
    }
  }
  .blue-card {
    background: #e6f7ff;
    border: 1px solid #91d5ff;
  }
  @media (min-width: 1024px) {
    ul.list-doc {
      display: flex;
      flex-flow: column wrap;
      height: 128px;
    }
  }
  @media screen and (max-width: 767px) {
    .help-card-right {
      display: none;
    }
  }
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;