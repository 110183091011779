.main-admin {
  .card-email-status .ant-divider {
    margin: 8px 0;
  }
  .card-bounce-wrapper {
    .card-bounce {
      .ant-card-body {
        padding: 0;
      }
      .card-bounce__body {
        .ant-divider {
          margin: 8px 0;
        }
      }
    }
  }
  .top-sender__card {
    .card-top-sender .ant-card-body {
      padding: 8px 24px 16px;
    }
  }
  p.chart-legend {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &::before {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 10px;
    }
    &:nth-of-type(1)::before {
      background-color: #e8684a;
    }
    &:nth-of-type(2)::before {
      background-color: #5b8ff9;
    }
    &:nth-of-type(3)::before {
      background-color: #5ad8a6;
    }
    &:nth-of-type(4)::before {
      background-color: #5d7092;
    }
    &:nth-of-type(5)::before {
      background-color: #f6bd16;
    }
  }
  .empty-chart-img {
    max-width: 320px;
  }
  .empty-chart-wrapper {
    height: 420px;
  }
  .email-send-graph .ant-card .ant-card-body,
  .email-summary-wrapper .ant-card-body {
    padding: 16px 24px;
  }
  .chart-text-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 12px 0;
    gap: 0.25rem;
    & > span.anticon.text-secondary {
      font-size: 28px;
      padding: 4px;
    }
  }
  @media screen and (max-width: 768px) {
    .top-sender__card {
      .ant-card-head-wrapper {
        flex-wrap: wrap;
        padding-block: 16px
      }
      .ant-card-head-title {
        overflow: visible;
        padding: 0;
      }
      .ant-card-extra {
        padding: 0;
      }
    }
  }
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;