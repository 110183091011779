/* styles.css */
.table_suppress {
  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

/* Apply specific font weight to the "Category" column */
.table_suppress .ant-table-cell.category-column {
  font-weight: 500;
}

.table_history .ant-table-cell.category-column {
  font-weight: 500;
}

.ant-tabs-top.billing-tab > .ant-tabs-nav {
  margin-bottom: 8px;
}

.ant-table-title {
  padding: 24px 0 8px;
}

@media screen and (max-width: 576px) {
  .suppress-summary-wrapper .ant-card-body .summary-row {
    flex-direction: column;
    align-items: flex-start;
  }
}

.suppress-user {
  .suppress-user-collapse {
    width: 100%;
    .ant-collapse-item {
      .ant-collapse-header {
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        .ant-collapse-extra {
          margin-left: 0;
          margin-right: auto;
        }
      }
    }
  }
  .formFilter {
    width: 100%;
    gap: 12px 32px;
    flex-flow: row nowrap;
    .ant-form-item {
      margin-right: 0;
      flex: 1;
      margin-bottom: 12px;
    }
    .ant-row {
      max-height: 32px;
      flex-flow: row nowrap;
    }
    .ant-form-item-label > label::after {
      margin: 0 4px 0 0;
    }
    .button-advance-filter {
      margin-left: auto;
    }
  }
  @media screen and (max-width: 1199px) {
    .formFilter {
      margin-bottom: 12px;
    }
    .search-reciever {
      width: auto;
      height: auto;
    }
    .CSV-btn {
      display: flex;
      padding: 5px 16px;
      justify-content: center;
      align-items: center;
      align-self: stretch;
    }
    .btn-add {
      display: flex;
      padding: 5px 16px;
      justify-content: center;
      align-items: center;
      align-self: stretch;
    }
    .title-text {
      width: auto;
    }
    .btn-group-suppress {
      width: auto;
    }
  }
  @media screen and (max-width: 767px) {
    .title-text {
      width: auto;
    }
    .btn-group-suppress {
      width: auto;
    }
    & .suppress-collapse.ant-collapse .header-suppress-email .ant-collapse-header {
      flex-flow: column-reverse wrap;
      align-items: flex-end;
      .title-suppress-email + div {
        column-gap: 16px;
      }
    }
    .formFilter {
      flex-flow: row wrap;
      & > div:nth-child(1) {
        flex-flow: row wrap;
      }
      .ant-form-item {
        flex: 1;
      }
    }
  }
  @media screen and (max-width: 575px) {
    .suppress-user .formFilter {
      flex-flow: row wrap;
      & > div:nth-child(1) {
        flex-flow: row wrap;
      }
      .ant-form-item {
        flex: 1;
      }
    }
    .ant-form .ant-form-item .ant-form-item-label {
      flex: none;
      max-width: 100%;
      &::after {
        content: ":";
        position: relative;
        top: -0.5px;
        margin: 0 4px 0 0;
      }
    }

    .ant-form .ant-form-item .ant-form-item-control {
      flex: content;
      max-width: 100%;
    }
  }
}
.filter-dropdown {
  color: red;
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;