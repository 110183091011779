.log-admin {
  & .log-collapse.ant-collapse {
    .header-log-email {
      background-color: #fff;
      .ant-collapse-header {
        flex-flow: row-reverse nowrap;
        gap: 12px 24px;
        align-items: center;
        padding: 24px;
        .ant-collapse-header-text {
          span {
            width: max-content;
            &.ant-icon {
              font-size: 10px;
              line-height: 10px;
            }
          }
        }
        .ant-collapse-extra {
          margin-left: 0;
          width: 100%;
        }
      }
      .ant-collapse-content-active .ant-collapse-content-box {
        align-items: center;
        padding: 0 24px 24px 24px;
      }
    }
  }

  .ant-table-wrapper .ant-table-pagination.ant-pagination {
    margin: 0;
    padding-top: 16px;
    background-color: #ffffff;
    padding-bottom: 16px;
    padding-right: 24px;
  }
  .drawer-log-detail {
    margin-top: 64px;
  }
  .ant-drawer .ant-drawer-header {
    border-bottom: none;
  }
  .title-text h5.ant-typography {
    margin-bottom: 0;
    font-weight: 500;
  }
  .drawer-log-detail.ant-drawer-open .ant-drawer-content-wrapper .ant-drawer-header {
    padding-top: 40px;
    padding-inline: 30px;
  }
  & .ant-select.domain-list {
    min-width: 146px;
    width: max-content;
  }
  & .ant-select.status-list {
    min-width: 137px;
    width: max-content;
  }
  & .ant-select.domain-list,
  .ant-select.status-list {
    > .ant-select-selector {
      padding-inline: 16px;
      > .ant-select-selection-placeholder {
        color: #000000d9;
      }
    }
  }
  .title-log-email {
    font-size: 16px;
    font-weight: 500;
  }
  .empty-wrapper {
    width: min(100%, 400px);
    margin-inline: auto;
    img {
      width: 300px;
      height: 240px;
    }
  }
  .email-log-table .ant-table-title {
    padding-inline: 8px;
  }
  .button-advance-filter {
    display: flex;
    gap: 8px;
  }
  .formFilter {
    flex-flow: row nowrap;
    gap: 12px 24px;
    & > div:nth-child(1) {
      display: flex;
      width: 100%;
      gap: 12px 24px;
    }
    .ant-form-item {
      margin-right: 0;
      flex: 1;
      .ant-row {
        flex-flow: row nowrap;
        max-height: 32px;
      }
    }
    .ant-form-item-label > label::after {
      margin: 0;
      margin-right: 4px;
    }
  }
  @media screen and (max-width: 1199px) {
    .formFilter {
      flex-flow: column wrap;
      & > div:nth-child(1) {
        flex-flow: row wrap;
      }
      .ant-form-item:not(:last-child) .ant-row .ant-col:nth-child(1) {
        flex: 0 0 auto;
        max-width: max-content;
      }
      .button-advance-filter {
        margin-left: auto;
      }
    }
  }
  @media screen and (max-width: 767px) {
    & .log-collapse.ant-collapse .header-log-email .ant-collapse-header {
      flex-flow: column-reverse wrap;
      align-items: flex-end;
      .title-log-email + div {
        column-gap: 16px;
      }
    }
    .formFilter {
      flex-flow: row wrap;
      & > div:nth-child(1) {
        flex-flow: row wrap;
      }
      .ant-form-item {
        flex: 1;
      }
    }
  }
}
.filter-dropdown {
  color: red;
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;