.log-email-user {
  .log-email-header {
    background-color: #fff;
  }
  & .log-collapse.ant-collapse {
    .log-email-header .ant-collapse-header {
      flex-direction: row-reverse;
      gap: 12px 24px;
      align-items: center;
      padding: 24px;
      .ant-collapse-header-text {
        span {
          width: max-content;
          &.ant-icon {
            font-size: 10px;
            line-height: 10px;
          }
        }
      }
      .ant-collapse-extra {
        margin-left: 0;
        width: 100%;
      }
    }
    & .ant-collapse-content-active .ant-collapse-content-box {
      align-items: center;
      padding: 0 24px 24px 24px;
    }
  }
  .ant-table-wrapper .ant-table-pagination.ant-pagination {
    margin: 0;
    padding-top: 16px;
    background-color: #ffffff;
    padding-bottom: 16px;
    padding-right: 24px;
  }
  .select-domain {
    min-width: 146px;
    width: max-content;
  }
  .select-status {
    min-width: 146px;
    width: max-content;
  }
  .empty-wrapper {
    width: min(100%, 400px);
    margin-inline: auto;
    img {
      width: 300px;
      height: 240px;
    }
  }
  .formFilter {
    flex-flow: row nowrap;
    gap: 12px 24px;
    & > div:nth-child(1) {
      display: flex;
      width: 100%;
      gap: 12px 24px;
    }
    .ant-form-item {
      margin-right: 0;
      flex: 1;
      .ant-row {
        flex-flow: row nowrap;
        max-height: 32px;
      }
    }
    .ant-form-item-label > label::after {
      margin: 0;
      margin-right: 4px;
    }
    .button-advance-filter {
      display: flex;
      gap: 8px;
    }
  }
  @media screen and (max-width: 1199px) {
    .formFilter {
      flex-flow: column wrap;
      & > div:nth-child(1) {
        flex-flow: row wrap;
      }
      .ant-form-item:not(:last-child) .ant-row .ant-col:nth-child(1) {
        flex: 0 0 auto;
        max-width: max-content;
      }
      .button-advance-filter {
        margin-left: auto;
      }
    }
  }
  @media screen and (max-width: 767px) {
    & .log-collapse.ant-collapse .log-email-header .ant-collapse-header {
      flex-flow: column-reverse wrap;
      align-items: flex-end;
      .title-log-email + div {
        column-gap: 16px;
      }
    }
    .formFilter {
      flex-flow: row wrap;
      & > div:nth-child(1) {
        flex-flow: row wrap;
      }
      .ant-form-item {
        flex: 1;
      }
    }
  }
}
.title-log-email .ant-drawer-title {
  font-size: 20px;
  line-height: 28px;
}

.drawer-log-detail {
  margin-top: 64px;
  &.ant-drawer-open .ant-drawer-content-wrapper .ant-drawer-header {
    padding-top: 40px;
    padding-inline: 30px;
  }
}

.ant-drawer .ant-drawer-header {
  border-bottom: none;
}

.ant-descriptions-bordered.drawer-desc-wrapper {
  .ant-descriptions-view {
    border: none;
    .ant-descriptions-row {
      .ant-descriptions-item-label {
        background: none;
        padding: 16px;
        border-right: none;
        width: 120px;
        & span {
          font-weight: 500;
        }
      }
    }
    .ant-descriptions-item-content {
      padding: 16px;
    }
  }
}

// @media only screen and (min-width: 1000px) and (max-width: 1150px) {
//   .ant-form-inline .ant-form-item {
//     &.form-sender {
//       min-width: 150px;
//       margin-right: 10px;
//       margin-bottom: 12px;
//     }
//     &.form-recipient {
//       min-width: 150px;
//       margin-right: 10px;
//       margin-bottom: 12px;
//     }
//     & .ant-form-inline .ant-form-item.form-daterange .ant-picker-range {
//       min-width: 150px;
//     }
//     &.button-advance-filter {
//       min-width: 100px;
//       margin-left: auto;
//       text-align: center;
//     }
//   }
// }

// @media only screen and (min-width: 1151px) and (max-width: 1400px) {
//   .ant-form-inline .ant-form-item {
//     &.form-sender {
//       min-width: 260px;
//       margin-right: 10px;
//       margin-bottom: 12px;
//     }
//     &.form-recipient {
//       min-width: 260px;
//       margin-right: 10px;
//       margin-bottom: 12px;
//     }
//     &.form-daterange .ant-picker-range {
//       min-width: 260px;
//     }
//     &.button-advance-filter {
//       min-width: 100px;
//       margin-left: auto;
//       text-align: center;
//     }
//   }
// }

// @media only screen and (min-width: 1401px) and (max-width: 2560px) {
//   .ant-form-inline .ant-form-item {
//     &.form-sender {
//       min-width: 25%;
//       margin-right: 0;
//     }
//     &.form-recipient {
//       min-width: 30%;
//       margin-right: 0;
//     }
//     &.form-daterange .ant-form-item-control-input-content {
//       min-width: 30%;
//       margin-right: 0;
//     }
//     & .ant-form-inline .ant-form-item.button-advance-filter {
//       margin-right: 0;
//     }
//   }
// }

// @media only screen and (min-width: 2561px) {
//   .ant-form-inline .ant-form-item {
//     &.form-sender {
//       min-width: 40%;
//       margin-right: 0;
//     }
//     &.form-recipient {
//       min-width: 40%;
//       margin-right: 0;
//     }
//     &.form-daterange .ant-form-item-control-input-content {
//       min-width: 40%;
//       margin-right: 0;
//     }
//   }
// }

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;