.smtp-alert-card {
  background: #fff1f0;
  border: 1px solid #ffccc7;
  .smtp-alert-left {
    padding: 24px 0 24px 24px;
    max-width: 936px;
  }
  .smtp-alert-right {
    padding-inline: 30px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  @media only screen and (max-width: 767px) {
    .smtp-alert-left {
      padding: 24px;
    }
    .smtp-alert-right {
      display: none;
    }
  }
}
.payment-alert__card {
  background: #fffbe6;
  border: 1px solid #ffe58f;
  .payment-alert__left {
    padding: 24px 0 24px 24px;
    max-width: 936px;
  }
  .payment-alert__right {
    padding-inline: 30px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.alert-qouta {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.over-qouta__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  border: 1px solid var(--dust-red-2, #ffccc7);
  background: var(--dust-red-1, #fff1f0);
  & .over-qouta {
    padding: 24px;
  }
  & .over-qouta__left {
    max-width: 932px;
    padding: 24px 0 24px 24px;
  }

  & .over-qouta__right {
    padding-inline: 30px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.over-qouta__warning {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  border: 1px solid var(--calendula-gold-3, #ffe58f);
  background: var(--calendula-gold-1, #fffbe6);
}

@media only screen and (max-width: 767px) {
  .over-qouta__right {
    display: none;
  }

  .upgrade {
    width: 170px;
    margin-bottom: 8px;
  }
}

.alert-card__wrapper {
  display: flex;
  justify-content: space-between;
  &.blue-bg {
    background: #e6f7ff;
    border: 1px solid #91d5ff;
  }
  &.red-bg {
    background: #fff1f0;
    border: 1px solid #ffccc7;
  }
  &.yellow-bg {
    background: #fffbe6;
    border: 1px solid #ffe58f;
  }
  .alert-card__left {
    padding: 24px 0 24px 24px;
    max-width: 936px;
  }
  .alert-card__right {
    padding-inline: 30px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  @media only screen and (max-width: 767px) {
    .alert-card__left {
      padding: 24px;
    }
    .alert-card__right {
      display: none;
    }
  }
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;