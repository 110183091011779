.inputDropdown {
  max-width: 256px;
  & .ant-input {
    padding: 4px 16px 4px 12px;
  }
  .ant-input-search-button {
    padding: 8px;
  }
  &__dropdown {
    display: inline-flex;
    justify-self: center;
    width: 100%;
    height: 30px;
    padding: 0 4px 0 8px;
  }
}
.inputDropdown__overlay {
  max-width: 248px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .inputDropdown__overlay {
    max-width: 256px;
  }
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;