.report-billing {
  .anticon.text-success {
    color: #0d9f3f;
  }
  .anticon.text-secondary {
    color: #00000073;
  }
  button.buttonNpwp {
    padding: 0;
    white-space: normal;
    text-align: left;
    height: auto;
  }
  .title-report-billing {
    font-size: 16px;
    font-weight: 500;
  }
  .img-profile {
    border-radius: 50%;
    width: 180px;
    height: 180px;
    object-fit: cover;
    object-position: center center;
  }
  .upload-btn .ant-upload {
    display: none;
  }
  .image-profile-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover .upload-btn .ant-upload {
      display: block;
    }
  }
  .rows-perusahaan {
    cursor: pointer;
  }
  .log-act-admin {
    width: 200px;
    max-width: 500px;
  }
  .report-billing-collapse {
    width: 100%;
    .ant-collapse-item {
      .ant-collapse-header {
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        .ant-collapse-extra {
          margin-left: 0;
          margin-right: auto;
        }
      }
    }
  }
  .ant-table-title {
    padding-top: 4px;
  }
  .contact-wrapper {
    padding: 8px;
    border-bottom: 1px solid #f0f0f0;
    .btn-profile {
      visibility: hidden;
    }
    &:hover .btn-profile {
      visibility: visible;
    }
  }
  .rowNpwp .ant-descriptions-item-content {
    flex-direction: column;
  }
  .empty-wrapper {
    width: min(100%, 400px);
    margin-inline: auto;
    img {
      width: 100%;
      height: 240px;
    }
  }
  .formFilter {
    width: 100%;
    gap: 12px 32px;
    flex-flow: row nowrap;
    .ant-form-item {
      margin-right: 0;
      flex: 1;
    }
    .ant-row {
      max-height: 32px;
      flex-flow: row nowrap;
    }
    .ant-form-item-label > label::after {
      margin: 0 4px 0 0;
    }
    .button-advance-filter {
      margin-left: auto;
    }
  }
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .colDetail.ant-col-xl-6 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .colDetail.ant-col-xl-18 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    :is(.company__column, .contact__column) {
      max-width: 100%;
      width: 100%;
      flex: 1;
    }
  }
  @media screen and (max-width: 1199px) {
    .formFilter {
      gap: 12px 16px;
      flex-flow: row wrap;
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .tableTitleAccordion__wrapper > div {
      column-gap: 10px;
      .title-text {
        white-space: nowrap;
      }
      .filter-select {
        min-width: 93px;
      }
      .btn-group-log > div {
        row-gap: 8px;
      }
    }
    .tableTitle__wrapper > div {
      column-gap: 10px;
      .title-text {
        white-space: nowrap;
      }
    }
  }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    .tableTitleAccordion__wrapper {
      .btn-group-log {
        flex-flow: row wrap-reverse;
        justify-content: end;
      }
      .input-company {
        flex: 1 1 auto;
        max-width: 216px;
      }
    }
    .tableTitle__wrapper {
      .btn-group-log > div {
        flex-flow: row wrap;
        row-gap: 8px;
        justify-content: flex-end;
      }
      .btn-group-log .input-company {
        flex: 1 1 auto;
        max-width: 216px;
      }
    }
  }
  @media (max-width: 647px) {
    .tableTitleAccordion__wrapper {
      .filter-text {
        margin-left: auto;
        order: 3;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .tableTitleAccordion__wrapper {
      & > div:nth-child(1) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 10px;
      }
      .btn-group-log {
        flex-flow: row wrap;
        width: 100%;
      }
      .formFilter {
        flex-flow: row wrap;
        width: 100%;
      }
      .input-company {
        flex: 1 1 50%;
      }
    }
  }
  @media screen and (max-width: 576px) {
    .tableTitleAccordion__wrapper {
      .formFilter {
        flex-flow: row wrap;
        width: 100%;
      }
      .ant-form .ant-form-item .ant-form-item-label {
        flex: none;
        max-width: 100%;
        &::after {
          content: ":";
          position: relative;
          top: -0.5px;
          margin: 0 4px 0 0;
        }
      }
      .ant-form .ant-form-item .ant-form-item-control {
        flex: content;
        max-width: 100%;
      }
    }
  }
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;