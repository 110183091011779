.documentation- {
  &summary__wrapper,
  &table__wrapper {
    .ant-card-body {
      padding: 16px 24px;
    }
  }
  &table__wrapper {
    .title-text h5 {
      white-space: nowrap;
    }
    .subject-documentation {
      white-space: initial;
    }
    .pageLimit__selector {
      min-width: 124px;
      width: 100%;
    }
    .input-company {
      min-width: 127px;
      max-width: 200px;
    }
    .formFilter {
      width: 100%;
      gap: 12px 32px;
      flex-flow: row nowrap;
      .ant-form-item {
        margin-right: 0;
        flex: 1;
      }
      .ant-row {
        max-height: 32px;
        flex-flow: row nowrap;
      }
      .button-advance-filter {
        margin-left: auto;
      }
    }
    .documentation__table {
      .subject-documentation {
        .btn-extra {
          visibility: hidden;
        }
      }
      & .documentation__row:hover .btn-extra {
        visibility: visible;
      }
    }
  }
  @media screen and (min-width:992px) and (max-width: 1199px) {
    &table__wrapper {
      .tableTitleAccordion__wrapper > div {
        column-gap: 10px;
      }
    }
  }
  @media screen and (max-width: 1199px) {
    &table__wrapper {
      .tableTitleAccordion__wrapper > div {
        column-gap: 10px;
      }
      .formFilter {
        gap: 12px 16px;
        flex-flow: row wrap;
      }
    }
  }
  @media screen and (max-width: 991px) {
    &table__wrapper {
      .btn-group-log {
        flex-flow: row nowrap;
        .input-company {
          max-width: 189px;
        }
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 823px) {
    &table__wrapper {
      .title-text {
        width: min-content;
      }
      .btn-group-log {
        flex-flow: row wrap;
        justify-content: right;
      }
      .filter-text {
        order: 4;
      }
    }
  }
  @media screen and (max-width: 767px) {
    &table__wrapper {
      .btn-group-log {
        flex-flow: row wrap;
        justify-content: flex-end;
        .filter-text {
          order: 1;
        }
        .input-company {
          width: 50%;
          order: 3;
        }
        & > div:nth-child(3) {
          order: 2;
        }
      }
    }
  }
  @media screen and (min-width: 576px) and (max-width: 626px) {
    &table__wrapper {
      .btn-group-log {
        .filter-text {
          order: 2;
        }
        .input-company {
          order: 3;
        }
        & > div:nth-child(3) {
          order: 1;
        }
      }
    }
  }
}
.documentationAdmin__edit {
  .documentationAdmin__left {
    max-width: 852px;
    width: 100%;
    padding: 16px 24px;
    background: white;
    ol {
      padding-inline-start: 20px;
    }
  }
  .documentationAdmin__right {
    max-width: 348px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    .ant-select.status-list {
      width: max-content;
      min-width: 82px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding: 0 8px;
    }
    .checkgroup__wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }
    .checkgroup {
      max-height: 128px;
      overflow: scroll;
      border: 1px solid #f0f0f0;
    }
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
}
.documentationAdmin__view {
  .previewDocumentation {
    padding: 24px;
    background-color: #fff;
    ol {
      padding-inline-start: 20px;
    }
  }
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;